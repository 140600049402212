$cart-link-size: 14px;



.cart-shipping-table {
    thead {
        display: none;
        @include media-breakpoint-up(lg) {
            display: table-header-group;
        }
    }
    th,
    td {
        flex: 0 0 40%;
        padding-left: 8px;
        padding-right: 8px;

        @include media-breakpoint-up(sm) {
            flex: 0 1 33%;
        }
        @include media-breakpoint-up(lg) {
            flex: 0 1 10%;
        }
    }
    td {
        box-sizing: border-box;
        display: block;
        padding-bottom: 5px;
        padding-top: 5px;
    }
    .btn-link {
        border: none;
        font-size: $cart-link-size;
    }
    .cart-header-row,
    .cart-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .cart-header-row {
        align-items: flex-end;
        border-bottom: none;
    }
    .cart-row {
        padding: map-get($grid-gutter-widths, xs) 0;
        &:first-of-type {
            padding-top: 0;
        }
    }
    .section-header {
        font-size: 16px !important;
        padding-bottom: 15px;
        padding-top: 15px;
        text-align: left;
    }
    .header-item-image-details,
    .item-image {
        float: left; // LEGACY: to support IE (no flexbox)
        order: 1;
        text-align: center;

        @include media-breakpoint-up(lg) {
            float: none;
        }
    }
    .header-item-image-details {
        flex-basis: 46%;
        order: 2;
        text-align: left;
    }
    .item-image {
        flex-basis: 33%;
        max-width: 33%;
        padding-left: 0;

        @include media-breakpoint-up(sm) {
            flex: 0 0 20%;
            max-width: 20%;
        }
        @include media-breakpoint-up(lg) {
            flex-basis: 8.5%;
        }

        img {
            width: 100%;
        }
    }
    .item-details {
        float: left; // LEGACY: to support IE (no flexbox)
        flex-basis: 67%;
        margin-bottom: map-get($grid-gutter-widths, xs);
        max-width: 67%;
        order: 2;

        @include media-breakpoint-up(sm) {
            flex-basis: 80%;
            max-width: 80%;
        }
        @include media-breakpoint-up(lg) {
            flex-basis: 37.5%;
            float: none;
            margin-bottom: 0;
            padding-right: 5px;
        }
        .name {
            font-size: 1rem;
        }
        // Removes Edit Details Quickview link for handheld devices
        .item-edit-details {
            font-size: $cart-link-size;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }
    .cart-attr-value {
        display: inline-block;
    }
    .header-delivery-options,
    .item-delivery-options {
        order: 6;
        text-align: left;
        min-width: 100%;

        @include media-breakpoint-down(xs) {
            flex-basis: 100%;
        }
        @include media-breakpoint-up (sm) {
            min-width: auto;
            order: 3;
        }
        @include media-breakpoint-up(lg) {
            flex-basis: 11%;
            width: 8rem;
        }
        .form-row {
            font-size: $cart-link-size;
            justify-content: flex-start;
            margin: 0;
        }
    }
    .item-delivery-options {
        @include media-breakpoint-up(lg) {
            display: inline;
        }
        input[type="radio"] {
            flex: 0 0 18px;
        }
        label {
            flex: 0 0 calc(100% - 18px);
            @include media-breakpoint-up(lg) {
                width: 85%;
                vertical-align: top;
            }
        }
    }
    .header-item-quantity,
    .item-quantity {
        float: left; // LEGACY: to support IE (no flexbox)
        max-width: 40%;
        order: 4;
        text-align: center;

        @include media-breakpoint-down(md) {
            padding-left: 0;
        }

        @include media-breakpoint-up(sm) {
            max-width: 33%;
        }

        @include media-breakpoint-up(lg) {
            flex-basis: 11.5%;
            float: none;
            max-width: 11.5%;
        }

        input {
            padding-left: 12px;
            width: 45px;
        }
        .c-qty {
            justify-content: center;

            input {
                margin-bottom: 6px;
            }
        }
    }
    .header-quantity-details,
    .item-quantity-details {
        float: left; // LEGACY: to support IE (no flexbox)
        order: 5;
        text-align: right;

        @include media-breakpoint-up(lg) {
            float: none;
            text-align: left;
        }
        .product-availability-list,
        .item-user-actions {
            font-size: $cart-link-size;
            text-align: right;

            @include media-breakpoint-up(lg) {
                margin: 0 5px;
                text-align: left;
            }
        }
        .product-availability-list {
            margin-bottom: 5px;
            padding: 0;

            .on-order, .not-available {
                color: $red;
                font-size: 0.9em;
            }
        }
        .item-user-actions {
            a,
            button {
                display: inline-block;
                font-size: 1em;
                margin-bottom: 5px;
                padding: 0;
                text-align: inherit;
                width: 100%;

                @include media-breakpoint-up(lg) {
                    width: auto;
                }
            }
        }
    }
    .header-indiv-price,
    .item-price {
        order: 6;
        text-align: right;
    }
    .header-item-shipping,
    .item-shipping {
        flex-basis: 55%;
        order: 5;
        text-align: right;

        @include media-breakpoint-up(md) {
            flex-basis: 38.5%;
        }

        select {
            background-color: transparent;
            border: 1px solid #000;
        }
    }
    .price-promotion {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        .price-sales,
        .price-standard {
            margin: 0 5px;
            text-align: right;
            width: 100%;
        }
        .price-sales {
            color: $red;
        }
        .price-standard {
            text-decoration: line-through;
        }
    }
    .header-total-price,
    .item-total {
        flex-basis: 11.5%;
        flex-grow: 2; // take over item-delivery-options that it is not there
        font-size: 1rem;
        font-weight: 700;
        order: 7;
        text-align: right;
        word-wrap: break-word;
        @include media-breakpoint-up(lg) {
            float: none;
            font-size: 1em;
        }
        .promo-adjustment {
            @include media-breakpoint-up(lg) {
                width: 100%;
                float: right;
            }
        }
    }
    .price-unadjusted {
        color: $gray-600;
        display: block;
        margin: 0 0 .42em;
        span {
            text-decoration: line-through;
        }
    }
    .price-adjusted-total {
        color: $gray-600;
        display: block;
        margin: .42em 0 0;
        span {
            color: $red;
        }
    }
    .gift-cert-delivery {
        color: $red;
    }
}

.product-list-item {
    .sku,
    .attribute {
        font-size: .8125em;
    }
}

.cart-promo {
    background: $gray-100;
    color: $gray-800;
    margin: 0.3em 0;
    padding: map-get($grid-gutter-widths, xs);

    td {
        padding: inherit;
    }

    .bonus-item-details {
        padding-bottom: 0;
    }
    .bonus-item-actions {
        font-size: 1em !important;
        padding-top: 0;
        text-align: right;
        vertical-align: middle;
    }
}

.cart-footer {
    @include clearfix();
    margin: .83em 0;
}

.cart-coupon-code {

    @include media-breakpoint-up(lg) {
        float: right;
        width: 60%;
    }

    &__inputs {
        display: flex;
        justify-content: end;
    }

    &__input {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 45%;
        }
    }

    &__button {
        margin-left: map-get($spacers, 2);

        &.button-fancy-large {
            min-width: auto;
        }

    }

    .error {
        color: $danger;
        padding-top: 0.4em;
        font-size: $font-size-sm;
    }

}

.cart-order-totals {
    margin: 1rem 0;
    @include media-breakpoint-up(lg) {
        margin-top: 0;
        width: 100%;
    }
    td {
        @include media-breakpoint-up(lg) {
            &:last-child {
                width: 190px;
            }
        }
    }
}

.order-totals-table {
    td {
        text-align: right;
    }

    .order-totals-item {
        width: 60%;
    }

    .order-totals-value {
        width: 40%;
    }

    .order-subtotal {
        font-weight: 700;
    }
    .order-total {
        font-weight: 700;
    }
}
.order-value {
    white-space: nowrap;
}

.final-sale-cart .final-sale-cart-wrapper {
    display: flex;
    justify-content: flex-end;
    .final-sale-cart-image {
        font-size: 18px;
        display: block;
        border: solid 1px #000;
        padding-left: 12px;
        border-right: none;
        margin-right: 0px;
        padding-top: 7px;
        padding-bottom: 6px;
    }
    .final-sale-cart-text {
        border: solid 1px #000;
        border-left: none;
        font-weight: 500;
        font-size: 14px;
        display: block;
        padding-left: 8px;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.cart-actions {
    @include clearfix();
    form,
    button {
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: auto;
        }
    }
    .checkout-button-submit-wrapper {
        padding-top: 2px;
        &.sticky {
            @include media-breakpoint-down(xs) {
                margin: 0;
                padding: 15px 18px;
                position: fixed;
                top: auto;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 101;
                border-top: 1px solid $gray-300;
                background-color: $white;
                .checkout-button-submit {
                    margin: 0;
                    width: 100%;
                }
            }
        }
    }
    form {
        margin-bottom: 10px;
    }
    .dw-apple-pay-button {
        font-size: .75rem;
        font-weight: $font-weight-bold;
        padding: .3em 2em;
        border-radius: 0;
        margin: 0;
        height: 48px;
        background-size: 52px;
        &:hover {
            padding: .3em 2em;
            min-width: 250px;
        }
    }

    button {
        @include media-breakpoint-up(sm) {
            min-width: 250px;
        }
    }
}

.cart-action-checkout {
    float: none;

    @include media-breakpoint-up(sm) {
        float: right;
    }
    fieldset {
        margin-bottom: 0;
    }
}

.cart-action-continue-shopping {
    @include media-breakpoint-up(lg) {
        float: right;
    }
    .cart-action-continue-shopping__button {
        margin-right: 1rem;
    }
}
.cart-actions-bottom {
    .checkout-button-submit {
        background-color: $blue;
        border-color: $blue;
    }
    @include media-breakpoint-down(xs) {
        .cart-action-continue-shopping__button {
            font-weight: 700;
            border: none;
            padding-top: 0;
            text-decoration: underline;
            text-decoration-thickness: 0.15em;
            text-underline-offset: 0.2em;
            &:hover, &:focus, &:active {
                background-color: none;
                border: none !important;
                box-shadow: none !important;
                color: $black !important;
            }
        }
        .dw-apple-pay-button {
            margin-top: 0;
            margin-bottom: 13px !important;
        }
    }
}

.cart-actions-top {
    display: none;
    margin-top: .5rem;
    margin-bottom: 1rem;

    .cart-action-continue-shopping {
        float: none;
    }
}


.cart-empty {
    @include clearfix();
    margin-top: 4rem;
    h1 {
        margin-bottom: 1rem;
    }
    .cart-actions {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .cart-action {
            margin-bottom: 1rem;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            max-width: 16rem;
            width: 100%;
        }
        .cart-action-continue-shopping form, .cart-action-continue-shopping fieldset {
            margin-bottom: 0;
        }
    }
    .product-listing {
        @include clearfix();
        background-color: $gray-100;
        padding: 1rem 0;
        h2 {
            margin-left: 1.3rem;
        }
        .search-result-items {
            margin-top: 1em;
        }
    }
}
.checkout-button-submit {
    margin-bottom: map-get($spacers, 3);
    position: relative;
    top: -2px;
}
.promo-adjustment {
    color: $red;
}

.account-welcome {
    .welcome-message {
        font-size: 1.2em;
        font-style: italic;
    }
    .not-user {
        margin: .83em 0;
    }
}

#password-reset{
    white-space: nowrap;
}

.oob-container {
    width: 100%;
    background: $background-gray;
    padding: 10px 20px;
    border: 2px solid $gray-300;
    display: flex;
    flex-direction: column;
    .header-oob {
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;
        .title-text {
            font-weight: bold;
            padding-bottom: 15px;
        }
        .instructions {
            font-weight: lighter;
        }
    }
    .oob-table {
        display: flex;
        flex-direction: row;
        .oob-col-header {
            font-weight: bold;
        }
        .oob-col {
            flex-grow: 1;
            &.reason {
                flex-grow: 2;
            }
            &.discount {
                display: flex;
                flex-direction: column;
            }
            &.applybutton button {
                color: $white;
                background-color: #be1e24;
                border-color: #be1e24;
            }
            .invalid {
                border-color: $form-feedback-invalid-color;
                color: $form-feedback-invalid-color;
            }
        }
        span.error {
            color: #ed001c;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: .5px;
        }
    }
}

#_atssh {
    z-index: 0 !important;
    display: none;
}
.hide-find-store-button #find-in-store{
    display:none;
}

.item-delivery-options {
    .ski-binding-product-section {
        display: none;
        pointer-events: none;
        background: #1c0e0e17;
    }
}

#return-to-top.has-sticky-checkout-btn {
    // adjust to not overlap with sticky checkout button on mobile cart page
    bottom: 87px;
}
