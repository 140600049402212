.checkout-progress-indicator {
    overflow: hidden;
    div {
        display: inline;
        padding: 0;
        white-space: nowrap;

        @include media-breakpoint-up(sm) {
            padding: 0 .83em 0 0;
        }

    }
    span {
        text-transform: uppercase;
    }
    .active span {
        font-weight: 700;
        color: $black;
    }
    a {
        color: $gray-800;
    }
    i {
        padding-left: .83em;
        color: $gray-400;
    }
    .step-3 {
        padding-right: 0;
    }

    .svg-symbol {
        display: inline-block;
        height: 1rem;
        width: 1rem;

        @include media-breakpoint-up(sm) {
            margin-left: 1rem;
        }

    }

}

#component_ApplePay {
    button {
        margin-top: 10px;
        height: 48px;
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 265px;
        }
    }
}


.ship-to-multiple {
    align-items: normal;
    margin-bottom: 20px;
}
.shiptomultiplebutton {
    align-self: normal;
}

.checkout-shipping, .checkout-billing {
    .c-form-row.countryselect {
        display: none;
    }
}

.signin-main-div {
    display: flex;
    justify-content: space-between;
}
.signin-sub-body,.Guestcheckout-content {
    flex-basis: 60%;
    fieldset {
        margin-bottom: 1rem;
        .btn-outline-dark:active {
            color: #000 !important;
        }
    }
}

.signin-sub-body {
    .login-box .login-rememberme {
        margin: 0
    }
    #password-reset {
        margin: 0;
    }
    .button-fancy-large {
        min-width: 100%;
    }
    .passwd-rem {
        display: flex;
        justify-content: space-between;
        .c-form-row__label {
            margin: 0;
        }
    }
}
.Guestcheckout-content {
    .button-fancy-large {
        min-width: 100%;
    }
}
.registersignin {
    border: none;
    background: transparent;
    padding: 0;
    &:focus {
        outline: none;
    }
}

.signindiv, .register-content-div fieldset, .guestsignlink {
    margin-bottom: 0.5rem;
    padding: 10px 10px 10px 30px;
    display: block;
    color: #000;
    text-decoration: none;
}

.signinContainer {
    flex-basis: 40%;
    max-width: 20%;
    border-right: 1px solid #e4e4e4;
}
.signindiv,.guestsignlink {
    &.active {
        border-left: 2px solid #066c06;
        background-color: #f4f4f4;
    }
    &:hover {
        text-decoration: none;
        color: #000;
    }
}

.guest-btn-link,.guest-btn-link:hover {
    color: #fff;
}

@media (max-width:480px) {
    .signin-main-div {
        flex-flow: column;
    }
    .signinContainer {
        display: flex;
        justify-content: space-around;
        max-width: 100%;
        border-right: none;
    }
    .signindiv, .register-content-div fieldset, .guestsignlink {
        padding: 10px 30px;
        white-space: nowrap;
    }
    .signin-sub-body, .Guestcheckout-content {
        padding-top: 40px;
    }
    .signindiv, .guestsignlink {
        &.active {
        border-bottom: 2px solid #066c06;
        background-color: transparent;
        border-left: none;
    }
    }
    .signin-sub-body,.Guestcheckout-content {
        .col-6 {
            max-width: 100%;
        }
    }
}

.gift-message-text {
    overflow: visible;
    opacity: 1;
    transition: all 1s ease;

    &.hidden {
        height: 0;
        margin: 0 !important;
        opacity: 0;
        overflow: hidden;
    }

    textarea {
        width: 100%;
    }

}

.redemption {
    .success {
        color: $success;
    }
    color: $red;
    span.error {
        margin-left: 0;
    }
}

.payment-method-options {
    padding-left: 40px;
    .label-inline label[for="is-PayPal"] {
        white-space: nowrap;
    }
}

.payment-method {
    clear: both;
    display: none;
    margin-bottom: 20px;
}

.payment-method-expanded {
    display: block;
}

.ccn-error {
    color: $danger;
    display: block;
    font-size: 13px;
    width: 100%;
}

.month,
.year,
.day {
    select {
        width: auto;
    }
}

.month .form-caption {
    margin-left: 0;
}

.cvn {
    clear: left;
    .field-wrapper {
        width: 50px;
    }
}

.ssn {
    clear: left;
}

.bml-terms-and-conditions {
    border: 1px solid;
    flex: 0 0 100%;
    font-size: 0.8em;
    height: 400px;
    overflow: auto;
    padding: 1em;

    @include media-breakpoint-up(sm) {
        flex: 0 0 66.6%;
    }
}

.bml-table-col-1 {
    width: 25%;
}

.pt_checkout {
    &.hide-apple-pay {
        .adyen-apple-pay-component, .payment-method-apple-pay, #payment-method-apple-pay {
            display: none !important;
        }
    }
    .desktop-only {
        display: block;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
    .mobile-only {
        display: none;
        @include media-breakpoint-down(md) {
            display: block;
        }
    }
    .c-top-promo-banner {
        margin: 0;
    }
    h3 {
        padding: 10px 0px 0;
    }
    .continue-button-row {
        align-items: center;
        justify-content: flex-end;
        padding-top: 5px;
    }

    .section-header {
        padding-right: 4rem;
        position: relative;

        .section-header-note {
            position: absolute;
                right: map-get($spacers, 2);
                bottom: 0;
            font-size: $font-size-sm;
            font-weight: 400;
            text-transform: none;
        }

    }

    #primary {
        width: 100%;
    }
    .cart-shipping-table {
        .cart-row {
            justify-content: flex-start;
        }
        .header-item-image-details {
            flex-basis: 50%;
        }
        .item-details {
            @include media-breakpoint-up(lg) {
                flex-basis: 41.5%;
            }
        }
        .item-quantity,
        .item-quantity-details,
        .item-total {
            @include media-breakpoint-down(md) {
                flex-basis: 33%;
            }
        }
        .item-quantity {
            text-align: right;

            @include media-breakpoint-up(lg) {
                text-align: center;
            }
        }
        .item-quantity-details {
            .product-availability-list {
                text-align: left;
            }
        }
        input,
        select {
            ~ .error {
                color: $danger;
                font-size: 13px;
            }
        }
    }
}
.checkout-mini-cart,
.checkout-order-totals {
    background-color: $white-three;
    i.fas {
        display: inline;
    }
}
.checkout-mini-cart {
    max-height: 400px;
    overflow: auto;
}

.checkout-order-totals {
    margin-bottom: 20px;
    padding: 0.3rem .5rem;
}

.place-order-totals {
    clear: both;
    .order-totals-table {
        float: right;

        @include media-breakpoint-up(sm) {
            width: 65%;
        }
        @include media-breakpoint-up(lg) {
            width: 100%;
        }

        td:nth-child(2) {
            padding: 0 0 0 10px;
        }
    }
}

.order-component-block {
    margin: 1.67em 0;
    .section-header a {
        float: right;
        font-weight: normal;
    }
    .details {
        background: none repeat scroll 0 0 $light;
        padding: 0.5em 1em;
    }
}

.order-summary-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;

    @include media-breakpoint-up(lg) {
        flex-direction: column-reverse;
        align-items: end;
    }

    h2 {
        font-size: 2em;
        font-style: italic;
        font-weight: normal;
    }
    .moneris-timeout,
    .order-totals-submit-form {
        flex-basis: 100%;
        max-width: 100%;
    }

    .moneris-timeout {
        @include media-breakpoint-up(md) {
            margin-bottom: 15px;
            justify-content: space-evenly;
        }
    }

    .order-totals-submit-form {
        padding-right: map-get($grid-gutter-widths, xs);

        @include media-breakpoint-up(md) {
            @include make-col(7);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(8);
        }
    }
    .moneris-timeout {
        @include media-breakpoint-up(md) {
            @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(8);
        }
    }
}

.summary-help {
    float: right;
    font-size: 0.8em;
    margin: 1em 0;
}

.submit-order {
    clear: both;
    display: block;
    padding: 1.67em 0 0;
    text-align: right;

    button {
        margin-left: 1.5em;
    }
}

.order-discount {
    color: $red;
}

.gift-cert-used {
    padding: .83em 0;
}

.summary .edit {
    position: absolute;
    text-transform: uppercase;
    right: 10px;
    font-size: 0.9em;
    line-height: 1.5em;
}

.pt_cart .breadcrumb {
    padding-left: 0;
}

.item-shipping-address {
    min-width: 120px;
}

.billing-error {
    color: red;
    margin: 0 0 .83em 24%;
}

.cvn-tooltip {
    font-weight: bold;
    margin: 5px;
    text-align: center;

    img {
        height: 150px;
        width: 110px;
    }
}

#shipping-method-list {
    position: relative;

    .standard-shipping {
        text-decoration: line-through;
    }
}
.moneris-timeout {
    line-height: 1;

    img {
        margin-bottom: 5px;
    }
    h2 {
        margin-bottom: 12px;
    }
    h2 {
        font-family: inherit;
        font-size: 26px;
        text-transform: uppercase;
    }

    .moneris-timeout-countdown,
    .moneris-timedout {
        color: #000;
        padding: 10px 12px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            padding: 10px 5px;
        }
        .moneris-timedout-flex {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            img {
                @include media-breakpoint-up(md) {
                    margin-right: 10px;
                }
            }
            p {
                margin-bottom: 0;
            }
        }
    }
    .moneris-timeout-countdown {
        background-color: #f4f2f1;
    }
    .moneris-timedout {
        background-color: #f4f2f1;
        display: none;
    }

    &--expired {
        .moneris-timeout-countdown {
            display: none;
        }
        .moneris-timedout {
            display: block;
        }
    }
}

.selected-shipping-method-list {
    .standard-shipping {
        text-decoration: line-through;
    }
}

fieldset.payment-method-fieldset, fieldset.gift-cards-fieldset, fieldset.billing-address-fieldset {
    margin-bottom: 0;
}
