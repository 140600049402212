.pt_product-search-result {
    .category-header {
        font-size: 36px;
        padding-top: 6px;
        text-align: center;
    }
    .category-body {
        text-align: center;
    }
}
.refinements {
    padding-bottom: map-get($grid-gutter-widths, xs);

    &#secondary {
        display: none;
        width: 100%;

        @include media-breakpoint-up(lg) {
            display: block;
            width: auto;
        }
    }
}

.refinements__inner {
    background-color: #fff;
}

// for sticky refinements sidebar.
// This basicaly allows the primary region
// to define the page height. Solves problem of footer
// jump when search results lists get short.
@include media-breakpoint-up(lg) {
    .refinements {
        .sticky-elem-wrap {
            height: 100%;
            overflow: auto;
            position: absolute;
                top: auto;
                right: map-get($grid-gutter-widths, lg) / 2;
                bottom: auto;
                left: map-get($grid-gutter-widths, lg) / 2;
                z-index: $zindex-sticky-elem;
            }
    }
}

#secondary {
    font-size: 14px;

    a {
        color: $blue;
        text-decoration: underline;
    }
    .account-nav-asset{
        margin: 0;
    }
}

#category-level-1 {
    li {
        color: $gray-600;
        display: block;
        .refinement-link {
            background: none repeat scroll 0 0 transparent;
            padding: 0;
        }
        &.expandable {
            .refinement-link {
                padding: 0;
            }
            .active {
                font-weight: bold;
                padding: 0 0 0 .2em;
            }
        }
    }
}

#category-level-2 {
    a {
        &.active {
            color: $gray-800;
            font-weight: 700;
        }
        &.refinement-link {
            margin: 0 0 0 .4em;
            padding: 0;
        }
    }
}
#category-level-2 {
    padding: .5em .1em 0 .7em;
}
#category-level-3 {
    padding: .3em 0 0;
    a {
        &.refinement-link {
            font-style: normal;
            margin: 0 0 0 .9em;
            padding: 0;
        }
    }
}

.refinement-header {
    background-color: $gray-100;
    border-top: 1px solid $gray-200;
    display: block;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 0.125em;
    margin: 0;
    padding: 1.15em .75em .75em;
    text-transform: uppercase;
}

.refinements-top-nav-bar {
    display: none;
}

.nav {
    .secondary-navigation,
    .refinement {
        ul {
            margin: -7px 0 0;

            li {
                margin-bottom: 0;
            }
        }
    }
}
#secondary {
    margin-bottom: map-get($spacers, 4);

    p {
        line-height: 1.7;
    }

    .secondary__header,
    .c-accordion__header {
        font-style: normal;
        margin: 0;
        padding: 14px 0;

        @include media-breakpoint-up(lg) {
            padding: 15px 0;
        }
    }
}
.secondary-navigation,
.refinement {
    li {
        padding: 6px 0;

        @include media-breakpoint-up(lg) {
            padding: 7px 0;
        }
    }
    .secondary__header,
    .c-accordion__header {
        font-weight: normal;
        letter-spacing: normal;
        padding: 16px 0;
        text-transform: none;

        @include media-breakpoint-up(lg) {
            padding: 18px 0 !important;
        }
    }
    .c-accordion__header {
        font-size: 16px;
    }
}
.secondary-navigation {
    margin-bottom: 16px;

    .secondary__header {
        &:first-child {
            padding-top: 11px;
        }
    }
}
.refinement {
    position: relative;

    // Hide Refinements on Category Landing Pages
    .pt_categorylanding & {
           display: none;
           &.category-refinement {
               display: block;
           }
       }
    h3 {
        font-size: 1.1em;
        letter-spacing: 0.125em;
        margin: 0 0 .65em;
        padding: 1.15em .75em .75em;
        text-transform: uppercase;
        .ie7 & {
            clear: both;
        }
    }
    ul {
        list-style: none outside none;
        margin: 0;
        padding: 3px 0 0;
    }
    ul {
        li {
            i {
                color: $gray-200;
            }
        }
        &.swatches li {
            margin-bottom: 1px;
            padding: 0;
            vertical-align: top;

            &.unselectable a, &.selectable-partial a {
                background-image: url("../images/interface/icon-color-swatch-unselectable.png");
                background-position: right top;
            }
        }

        &.refinementColor {
            li a {
                background-clip: padding-box;
                border-color: #fff;
                border-width: 3px;
                box-shadow: inset 0 0 3px 1px rgba(0,0,0,0.2);
                display: inline-block;
                height: 38px;
                position: relative;
                vertical-align: top;
                width: 38px;
            }
            .unselectable, .selectable-partial {
                border-color: transparent;

                a {
                    overflow: hidden;

                    &::before,
                    &::after {
                        content: '';
                        height: 100%;
                        position: absolute;
                        top: 0;
                    }
                    &::before {
                        background-color: rgba(127, 127, 127, .35);
                        border: 2px solid rgba(0, 0, 0, 0.22);
                        border-radius: inherit;
                        left: 0;
                        width: 100%;
                        z-index: 2;
                    }
                    &::after {
                        background-color: rgba(0, 0, 0, 0.22);
                        height: 100%;
                        left: 50%;
                        transform: translateX(-50%) rotateZ(45deg);
                        width: 2px;
                        z-index: 3;
                    }
                }
            }
        }
    }
    a {
        color: $body-color !important;
        text-decoration: none !important;
    }
    .refinement-options {
        display: flex;
        justify-content: flex-end;
    }
    .clear-refinement {
        flex: 0 0 50%;
        font-weight: bold;
        padding: 0 0 0 1.05em;
        text-align: right;

        a {
            &::after {
                background-size: contain;
                content: 'x';
                display: inline-block;
                height: 12px;
                margin-left: 8px;
                width: 12px;
            }
        }
    }
    .refinement-link, .refinement-link-checkbox {
        display: flex;
        width: 100%;
        border: 0 none;

        .refinement-link-hit-count {
            color: $gray-500;
            display: block;
            font-size: 12px;
        }

    }
    &.category-refinement .refinement-link div.refinement-link-hit-count {
        display: none;
    }
    .refinement-link-checkbox {
        position: relative;

        .refinement-link-name {
            padding-left: 1.4rem;

            &::before {
                position: absolute;
                left: 0;
                border: 1px solid $light-grey;
                content: '';
                display: inline-block;
                height: 1rem;
                width: 1rem;
            }
        }
    }
    .selected {
        .refinement-link-name {
            &::before {
                @include background-svg($svg-checkbox-filled);

                background-size: contain;
                border-color: $blue;
            }
        }
    }
    .refinement-link-name {
        flex-grow: 1;
    }
    .refinement-top-level {
        font-weight: $font-weight-bold;
        padding: 7px 0;

        &__view-all {
            font-size: 12px;
        }
    }
    .refinement-see-all {
        flex: 0 0 50%;
        text-align: left;

        button {
            border: none;
            color: inherit;
            padding-bottom: 0;
        }
    }

    &.c-accordion {
        .c-accordion__item {
            &:first-of-type {
                border-top: none;
            }
            &.is-expanded {
                .c-accordion__body {
                    margin-top: -7px;
                    padding: 0 0 14px;
                    position: relative;
                }
            }
        }
    }
    .c-accordion__body {
        padding-left: 0;
        padding-right: 0;

        &.c-accordion__see-all {
            ul {
                max-height: inherit !important;
            }
            .refinement-see-all__see-all {
                display: none;
            }
            .refinement-see-all__hide-extra {
                display: inline;
            }
        }
        li {
            padding-left: 0;
            padding-right: 1rem;
        }
        &.has-cutoff {
            ul {
                max-height: 222px; //Fallback, equivalent to --cutoff equals 6
                max-height: calc(12px + 35px * var(--cutoff, 5));
                overflow: hidden;
                transition: max-height .15s;
            }
        }
        .refinement-see-all__see-all,
        .refinement-see-all__hide-extra {
            &::before {
                display: inline-block;
                font-size: 18px;
                font-weight: bold;
                line-height: 1;
                margin-right: 7px;
                vertical-align: top;
            }
        }
        .refinement-see-all__see-all {
            &::before {
                content: '+';
            }
        }
        .refinement-see-all__hide-extra {
            display: none;

            &::before {
                content: '-';
            }
        }
    }
}

.mobile-refinement-filter {
    &.search-result-options {
        padding-top: 0;
    }

    .mobile-refinement-filter__c-accordion__item {
        border: none !important;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row-reverse;

        @include media-breakpoint-down(xs) {
            margin: 0 -18px;
        }

        @include media-breakpoint-up(lg) {
            display: block;
        }

        &.is-expanded {
            .mobile-refinement-filter__c-accordion__header {
                @include background-svg($svg-accordion-up);
                border-bottom-color: $blue;

                @include media-breakpoint-down(xs) {
                    width: calc(50% - 6px);
                }
            }
            .mobile-refinement-filter__c-accordion__body {
                max-height: 5000px !important;
                overflow: auto;
                padding-top: 8px;
            }
        }
    }
    .mobile-refinement-filter__c-accordion__header {
        @include background-svg($svg-dropdown-arrow);

        align-items: center;
        background-position: calc(100% - 10px) center;
        border: 1px solid $light-grey;
        flex: 0 0 150px;
        font-size: 13px;
        letter-spacing: .3px;
        line-height: normal;
        padding: 10.8px 10px !important;
        text-transform: none;
        color: $gray-700;

        span {
            margin-right: 20px;
        }

        @include media-breakpoint-down(xs) {
            width: calc(50% - 10px);
            flex: none !important;
        }
    }
    .mobile-refinement-filter__c-accordion__body {
        width: 100%;
    }
    .refinements {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &.search-result-options {
        .sort-by {
            font-size: 12px;
            display: block;
            padding-bottom: 0;

            @include media-breakpoint-down(xs) {
                width: calc(50% - 6px);
                flex: none !important;
            }

            @include media-breakpoint-up(sm) {
                min-width: 150px;
            }
        }

        .input-select--alt {
            min-width: initial;
        }
    }
}

.c-accordion__item:not(.is-expanded) #mobile-refinements {
    display: none;
}

#mobile-refinements {
    z-index: 990;
    position: fixed;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    div.sticky-elem-wrap {
        font-size: 13px;
        position: relative;
        height: calc(100vh - 59px);
        width: 100vw;
        bottom: 0;
        left: 0;
    }
    .refinements-top-nav-bar {
        font-size: inherit;
        position:fixed;
        z-index: 5;
        width: 100%;
        top: 59px;
        height: 33px;
        display: flex;
        justify-content: space-between;

        color: #000;
        background-color: #fff;
        border-bottom: 1px solid $light-grey-three;

        a, div.js-accordion__target {
            display:inline-block;
            color: inherit;
            line-height: 2rem;

            i.fas {
                margin-left: 1rem;
                margin-right: 0.5rem;
            }
            .refinement-nav-toggle__icon--close {
                height: 2rem;
                width: 2rem;
                margin-right: 0.3rem;
            }
        }
    }

    .refinements__inner {
        position: relative;
        height: calc(100% - 103px);
        width: 100%;
        margin-top: 92px;
        margin-bottom: 70px;
        overflow: scroll;
        border: none;
        background: linear-gradient(to right, $light-grey-two 35%, $light-grey-three calc(35% + 1px), #fff 33%);
    }

    .refinement {
        &:nth-last-of-type(2) {
            // mobile iOS fix for refinements cutting off
            margin-bottom: 120px;
        }
        .c-accordion__header {
            display: block;
            font-size: 13px;
            padding: 1rem 1.5rem;
            min-height: 2rem;
            width: 35%;
        }
        &.has-selected-refinement div.c-accordion__header {
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                width: 0.5rem;
                height: 0.5rem;
                left: 0.5rem;
                background: #007ac3;
                border: 0;
                border-radius: 50%;
            }
        }

        .c-accordion__body {
            display: none;
            li:not(.color-swatch) {
                padding-top: 1rem;
                padding-bottom: 1rem;
                padding-right: .5rem;
                border-bottom: 1px solid $light-grey-three;
            }
        }
        .c-accordion__item.is-expanded {
            .c-accordion__header {
                background-color: #fff;
                box-shadow: 3px 0px 0px 0px #fff;
            }
            .c-accordion__body {
                z-index: 2;
                display: block;
                width: 59%;
                position: fixed;
                top: 100px;
                right: 3%;
                max-height: calc(100% - 170px);
                overflow: auto;

                &.has-cutoff {
                    ul {
                        max-height: unset;
                        overflow: visible;
                    }
                }

                // always show a scroll bar if content overflows on mobile
                -webkit-overflow-scrolling: auto;
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                }

                &::-webkit-scrollbar:vertical {
                    width: 8px;
                }

                &::-webkit-scrollbar:horizontal {
                    height: 8px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $gray-300;
                    border-radius: 10px;
                    border: 2px solid #ffffff;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: #ffffff;
                }
            }
        }

        .refinement-options {
            margin-top: 0.5rem;
            margin-right: 1rem;
        }

        .js-accordion__see-all {display: none;}
    }
    .clear-refinement {
        white-space: nowrap;
    }
    .c-accordion__done {
        z-index: 5;
        position: fixed;
        bottom: 0;
        padding: 10px 0;
        width: 100%;
        background-color: #fff;
        border-top: 1px solid $light-grey-three;

        button.btn-outline-primary, .button.btn-outline-primary {
            width: calc(100% - 20px);
            margin-left: 10px;
            background-color: #000;
            color: #fff;
            border: none;
        }
    }
}

.scrollable {
    height: 10em;
    overflow: auto;
}

.nav {
    ul {
        border: 0 none;
        list-style: none outside none;
        margin: .58em 0;
        padding: 0;
        li {
            list-style: none outside none !important;
            margin: 0 0 .58em;
        }
    }
    .toggle {
        border: 0 none;
        display: block;
        font-size: 1em;
        font-weight: 700;
        margin: .83em 0 .58em;
        text-transform: uppercase;
        span {
            background: none repeat scroll 0 0 transparent;
        }
    }
    .expanded {
        background-position: left -96px;
    }
}

#primary .search-result-content .infinite-scroll-div.auto-infinite-scroll {
    position: relative;
    div.loader {
        top: 0;
        height: 100%;
        width: 100%;
        background-position-x: 50%;
        background-attachment: unset;
        @include media-breakpoint-down(md) {
            position: absolute;
            height: 100%;
            width: 100%;
        }
    }
}

@include media-breakpoint-down(md) {
    .js-mobile-hide {
        display: none!important;
    }
    .js-mobile-scroll-disable {
        overflow: hidden;
    }
    #primary div.loader {
        position: fixed;
        background-attachment: scroll;
        height: 100vh;
        width: 100vw;
        z-index: 999;
    }
}
